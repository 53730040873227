import React from 'react'

import { getAssetFor } from 'kiss/assets/redux'
import { getRouteFor, CROWDFUNDING } from 'kiss/routes/redux'
import { useSelector } from 'react-redux'

import { useTranslation } from 'kiss/hooks/use-translation'

import { Text, mq, pxToRem } from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledLegalText = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`

/* PARTNERS */

const StyledPartnerContainer = styled.div`
  display: flex;
  gap: ${pxToRem(20)};
  margin-top: ${pxToRem(20)};
  flex-direction: column;

  @media ${mq.tablet} {
    flex-direction: row;
  }
  @media ${mq.desktop} {
    flex-direction: row;
  }
`

const StyledPartnerBlock = styled.div`
  border-radius: 10px;
  background: var(--Grey-800, #2D2D2D);
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  @media ${mq.tablet} {
    width: 50%;
    justify-content: flex-start;
    // flex-direction: row;
  }
  @media ${mq.desktop} {
    width: 50%;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
`

const StyledFirstPartnersBlock = styled.div`
  display: flex;
  gap: 20px;
  align-self: flex-start;

  @media ${mq.desktop} {
    align-items: center;
    align-self: center;
  }
`

const StyledSecondImageBlock = styled.div`
  align-self: flex-start;

  @media ${mq.desktop} {
    align-self: center;
  }
}
`

export const Partners = (props) => {
  const t = useTranslation()

  const assetFor = useSelector(getAssetFor)
  const routeFor = useSelector(getRouteFor)

  return (
    <StyledPartnerContainer {...props}>
      <StyledPartnerBlock>
        <StyledFirstPartnersBlock>
          <img
            src={assetFor('footer/membre_fpf_2022.svg')}
            alt={t('layouts.footer.alt_fpf_membre_logo')}
            width="60"
            height="60"
          />
          <img
            src={assetFor('footer/french-authorities.png')}
            alt={t('layouts.footer.alt_french_authorities_logo')}
            width="60"
            height="60"
          />
        </StyledFirstPartnersBlock>
        <StyledLegalText
          cssColor="var(--color-grey-400)"
          className="k-Footer__partners--text"
          family="generalsans"
        >
          {t('layouts.footer.french_authority', {
            parseHtml: true,
            route: routeFor(CROWDFUNDING),
          })}
        </StyledLegalText>
      </StyledPartnerBlock>

      <StyledPartnerBlock>
        <StyledSecondImageBlock>
          <img
            src={assetFor('footer/mangopay.svg')}
            alt={t('layouts.footer.alt_mangopay_logo')}
            width="140"
            height="60"
          />
        </StyledSecondImageBlock>
        <StyledLegalText
          cssColor="var(--color-grey-400)"
          className="k-Footer__partners--text"
          family="generalsans"
        >
          {t('layouts.footer.mangopay', { parseHtml: true })}
        </StyledLegalText>
      </StyledPartnerBlock>
    </StyledPartnerContainer>
  )
}
