import { DevGrid, domElementHelper } from '@kisskissbankbank/kitten'
import { removeAllAlerts } from 'kiss/app/alerts/redux'
import ErrorBoundary from 'kiss/app/layouts/error-boundary'
import { getLocale, getSSRHost } from 'kiss/app/redux'
import { getAssetFor } from 'kiss/assets/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { isCurrentUserAdmin as isCurrentUserAdminSelector } from 'kiss/session/redux'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const PageLayout = ({ children, className }) => {
  const t = useTranslation()
  const location = useLocation()
  const assetFor = useSelector(getAssetFor)
  const locale = useSelector(getLocale)
  const host = useSelector(getSSRHost)
  const { prefersLightMode } = useMediaQuery()
  const isCurrentUserAdmin = useSelector(isCurrentUserAdminSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(removeAllAlerts())
  }, [location.pathname])

  return (
    <>
      <Helmet
        titleTemplate="%s — KissKissBankBank"
        defaultTitle="KissKissBankBank"
        meta={[
          {
            charset: 'utf-8',
          },
          {
            name: 'description',
            content: t('layouts.head.meta_description'),
          },
          {
            property: 'author',
            content: 'KissKissBankBank',
          },
          {
            property: 'og:site_name',
            content: 'KissKissBankBank',
          },
          {
            property: 'og:url',
            content: `${host}${location.pathname}`,
          },
          {
            property: 'og:locale',
            content: locale,
          },
          {
            property: 'og:ttl',
            // Cf. https://stackoverflow.com/a/51182762
            content: 2419200,
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.0',
          },
        ]}
        link={[
          {
            href: assetFor(
              prefersLightMode
                ? 'brand/favicon-192x192.png'
                : 'brand/favicon-192x192_white.png',
            ),
            rel: 'shortcut icon',
            type: 'image/png',
          },
          {
            href: assetFor(
              prefersLightMode
                ? 'brand/favicon-192x192.png'
                : 'brand/favicon-192x192_white.png',
            ),
            rel: 'icon',
            type: 'image/png',
            sizes: '192x192',
          },
          {
            href: assetFor(
              prefersLightMode
                ? 'brand/favicon-32x32.png'
                : 'brand/favicon-32x32_white.png',
            ),
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
          },
          {
            href: assetFor(
              prefersLightMode
                ? 'brand/favicon-16x16.png'
                : 'brand/favicon-16x16_white.png',
            ),
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
          },
          {
            href: '/pwa/icon-ios.png',
            rel: 'apple-touch-icon',
            sizes: '192x192',
          },
          {
            href: 'https://d3v4jsc54141g1.cloudfront.net/GeneralSans/fonts_v3.css',
            rel: 'stylesheet',
            type: 'text/css',
            media: 'screen',
          },
          {
            href: assetFor('reset.css'),
            rel: 'stylesheet',
            type: 'text/css',
            media: 'screen',
          },
        ]}
      />
      <ErrorBoundary>
        <div className={className}>{children}</div>
      </ErrorBoundary>
      {domElementHelper.canUseDom() &&
        (APP_ENV !== 'production' || isCurrentUserAdmin) && <DevGrid />}
    </>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout
