import React from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'kiss/hooks/use-translation'

import { getSuccessfulProjectsCount } from 'kiss/modules/home/redux'
import { getAssetFor } from 'kiss/assets/redux'
import { getRouteFor, FUNNEL } from 'kiss/routes/redux'

import { pxToRem, Text, Button, ScreenConfig } from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const HeroWrapper = styled.section`
  display: flex;
  align-items: center;
  max-width: ${pxToRem(1240)};
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  padding: ${pxToRem(20)} ${pxToRem(20)} ${pxToRem(30)} ${pxToRem(20)};

  @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
    flex-direction: column-reverse;
  }
  
  @media (min-width: 40rem) {
    padding: ${pxToRem(30)} ${pxToRem(40)} ${pxToRem(40)} ${pxToRem(40)};
  }

  @media (min-width: 67.5rem) {
    padding: ${pxToRem(30)} ${pxToRem(20)} ${pxToRem(47)} ${pxToRem(20)};
  }

  @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
    .kiss-Homepage__hero__main {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: ${pxToRem(20)};
    }
  }
  @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
    .kiss-Homepage__hero__main {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: ${pxToRem(20)};
    }
  }

  .kiss-Homepage__hero__img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    display: block;

    img {
      display: block;
      width: 100%;
      object-position: center;
    }
  }

  @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
    .kiss-Homepage__hero__img {
      width: 60%;
    }

  @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
    .kiss-Homepage__hero__img {
      img {
        display: block;
        max-height: ${pxToRem(360)};
        width: 100%;
        object-position: center;
      }
    }
  }
`

const StyledTitle = styled(Text)`
  font-size: ${pxToRem(24)};
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

  @media (min-width: 40rem) {
    font-size: ${pxToRem(32)};
    line-height: 34px;
  }

  @media (min-width: 67.5rem) {
    font-size: ${pxToRem(36)};
    line-height: 46px;
  }
`

const StyledParagraph = styled(Text)`
  font-size: ${pxToRem(16)};
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 40rem) {
    font-size: ${pxToRem(18)};
  }

  @media (min-width: 67.5rem) {
    font-size: ${pxToRem(20)};
  }
`

const Hero = () => {
  const t = useTranslation()

  const assetFor = useSelector(getAssetFor)

  const successfulProjectsCount = useSelector(getSuccessfulProjectsCount)

  const routeFor = useSelector(getRouteFor)

  return (
    <HeroWrapper>
      <div className="kiss-Homepage__hero__main">
        <StyledTitle family="antiqueolive">
          {t('new_home.hero.title')}
        </StyledTitle>
        <StyledParagraph>
          <span>
            {t('new_home.hero.description', {
              projectsCount: t(successfulProjectsCount, {
                formatNumber: true,
              }),
              parseHtml: true,
            })}
          </span>
        </StyledParagraph>
        <Button as="a" modifier="helium" href={routeFor(FUNNEL)}>
          {t('new_home.under_hero.button')}
        </Button>
      </div>
      <div className="kiss-Homepage__hero__img">
        <img src={assetFor('illustrations/home/Cover.svg')} />
      </div>
    </HeroWrapper>
  )
}

export default Hero
