import React from 'react'
import {
  Checkbox,
  Grid,
  GridCol,
  LockOutlineIcon,
  Tag,
  Text,
  TextCopy,
} from '@kisskissbankbank/kitten'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  disableShareProject,
  enableShareProject,
} from 'kiss/modules/project-page/page-state/actions'
import {
  getProjectState,
  getProjectUrl,
  getDraftPageToken,
  isDraftPageSharingEnabled,
  hasFeature,
} from '../../../../page-state/selectors'

const PROJECT_STATUS_CAN_SHARE = ['draft', 'submitted', 'improve', 'accepted']

const PreviewLink = () => {
  const t = useTranslation()
  const dispatch = useDispatch()

  const draftSharingEnabled = useSelector(isDraftPageSharingEnabled)
  const draftToken = useSelector(getDraftPageToken)
  const projectUrl = useSelector(getProjectUrl)
  const state = useSelector(getProjectState)

  const hasFeatureSelecetor = useSelector(hasFeature)
  const hasShareLinkFeature = hasFeatureSelecetor('draft_share')

  const onClickForShare = async () => {
    if (!draftSharingEnabled) {
      return dispatch(enableShareProject())
    }
    return dispatch(disableShareProject())
  }

  if (!PROJECT_STATUS_CAN_SHARE.includes(state)) return null

  if (!hasShareLinkFeature) {
    return (
      <Checkbox
        disabled
        className="k-u-flex k-u-flex-alignItems-center"
        label={
          <div className="k-u-flex k-u-flex-alignItems-center">
            <Text weight="600" size="medium">
              {t('projects.owner_panel.sharing_link_blocked')}
            </Text>
            <Tag
              variant="dark"
              type="disabled"
              flex
              className="k-u-margin-left-single"
            >
              <LockOutlineIcon width="10" className="k-u-margin-none" />
              {t('projects.owner_panel.pro_tag')}
            </Tag>
          </div>
        }
      />
    )
  }

  return (
    <>
      <Checkbox
        className="k-u-margin-none"
        label={t('projects.owner_panel.activate_sharing_link')}
        onClick={onClickForShare}
        checked={draftSharingEnabled}
      >
        {t('projects.owner_panel.activate_sharing_link_description')}
      </Checkbox>
      {draftSharingEnabled && (
        <Grid>
          <GridCol col-m="6">
            <TextCopy
              alertMessage={t('projects.owner_panel.link_copy_confirmation')}
              buttonText={t('projects.owner_panel.copy_link')}
              description={t('projects.owner_panel.link_copy_description')}
              forceOneLine
              size="small"
              modifier="helium"
            >{`${projectUrl}/preview?draft_page_token=${draftToken}`}</TextCopy>
          </GridCol>
        </Grid>
      )}
    </>
  )
}

export default PreviewLink
