import getOr from 'lodash/fp/getOr'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'

import { createSelector } from '@reduxjs/toolkit'

export const NAME = 'HOME'
export const PRICING_SECTION = 'pricing-section'

export const getSlices = (state) => getOr({})(`${NAME}.slices`)(state)

export const getPopularProjects = createSelector(
  (state) => state[NAME]?.popularProjects?.edges || [],
  (edges) => edges.map(({ node }) => node),
)

export const getSelectedProjects = createSelector(
  (state) => state[NAME]?.selectedProjects?.edges || [],
  (edges) => edges.map(({ node }) => node),
)

export const getCultureEngagementProjects = (state) =>
  flow(
    getOr([])(`${NAME}.cultureEngagementProjects.edges`),
    map(({ node }) => node),
  )(state)

export const getAgainstDiscriminationsEngagementProjects = (state) =>
  flow(
    getOr([])(`${NAME}.againstDiscriminationsEngagementProjects.edges`),
    map(({ node }) => node),
  )(state)

export const getEnvironmentEngagementProjects = (state) =>
  flow(
    getOr([])(`${NAME}.environmentEngagementProjects.edges`),
    map(({ node }) => node),
  )(state)

export const getPartnerships = (state) => {
  return getOr([])(`${NAME}.partnerships`)(state)
}

export const getSuccessfulProjectsCount = (state) => {
  return getOr([])(`${NAME}.stats.successfulProjectsCount`)(state)
}

export const getNewsSlices = createSelector([getSlices], (slices) => {
  return slices.homepage_news
})

export const getSeoSlice = createSelector([getSlices], (slices) => {
  return slices.homepage_seo[0]
})
